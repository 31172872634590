import React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import Select from "react-select";
import { IMaskInput } from "react-imask";

const Input = ({
  type,
  isMultiple,
  limit,
  label,
  placeholder,
  name,
  id,
  onChangeFunc,
  value,
  checked,
  defaultValue,
  enable,
  selectedValue
}) => {
  let [blockedOptions, setBlockOptions] = useState(false);
  let [Date, setDate] = useState(dayjs())

  function activateFunc(evt) {
    onChangeFunc(evt);
  }
  
  return (
    <>
      {type === "text" ? (
        <div className="em-c-field em-u-max-width-30  ">
          <label htmlFor="input" className="em-c-field__label">
            {label}
          </label>
          <div className="em-c-field__body products">
            <input
              type="text"
              id={id}
              name={name}
              className="em-c-input em-js-input"
              placeholder={placeholder}
              aria-describedby="textinput-note-:r23:"
              onChange={onChangeFunc}
              value= {value}
              disabled={!enable}
            />
          </div>
        </div>
      ) : type === "datetime" ? (
        <div className="em-c-field em-u-max-width-30  ">
          <label htmlFor="input" className="em-c-field__label">
            {label}
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                onChange={(evt) => activateFunc(evt)}
                value={Date}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      ) : type === "select" ? (
        <div className="em-c-field em-u-max-width-30  ">
          <label htmlFor="select" className="em-c-field__label">
            {label}
          </label>
          <div className="em-c-field__body">
            <Select
              closeMenuOnSelect={!isMultiple}
              isMulti={isMultiple}
              options={limit == undefined ? value : blockedOptions ? [] : value}
              onChange={(e) => {
                isMultiple
                  ? e.length < limit
                    ? (onChangeFunc(e), setBlockOptions(false))
                    : (setBlockOptions(true), onChangeFunc(e))
                  : (setBlockOptions(false), onChangeFunc(e));
              }}
              name={name}
              isDisabled = {!enable}
              defaultValue={value.filter(v => {return v.value == selectedValue})}
            />
          </div>
        </div>
      ) : type === "checkbox" ? (
        <label
          className="em-c-input-group em-c-checkbox--container"
          htmlFor={name}
        >
          <input
            id={id}
            name={name}
            type="checkbox"
            className="em-c-input-group__control em-js-checkbox-trigger"
            defaultValue={value}
            onChange={onChangeFunc}
            checked={checked}
            disabled={!enable}
          />
          <span className="em-c-checkbox--checkmark"></span>
        </label>
      ) : type === "phone" ? (
        <div className="em-c-field em-u-max-width-30  ">
          <label htmlFor="input" className="em-c-field__label">
            {label}
          </label>
          <div className="em-c-field__body products" id="phone-input">
          <IMaskInput
            mask="+00"
            placeholder="+"
            className="phone-cc"
            onChange={onChangeFunc[0]}
            value= {value[0]} 
          />
          <IMaskInput
            mask="(000) 000000000000"
            placeholder="Phone number"
            className="phone-tel"
            onChange={onChangeFunc[1]}
            value= {value[1]} 
          />
          </div>
        </div>
      ) : type === "email" ? (
          <div className="em-c-field em-u-max-width-30  ">
            <label htmlFor="input" className="em-c-field__label">
              {label}
            </label>
            <div className="em-c-field__body products">
              <input
                type="email"
                id={id}
                name={name}
                className="em-c-input em-js-input"
                placeholder={placeholder}
                aria-describedby="textinput-note-:r23:"
                onChange={onChangeFunc}
                value= {value} 
              />
            </div>
          </div>
        ) : (
        <></>
      )}
    </>
  );
};

export default Input;
