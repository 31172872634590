import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

function TabElment({tabs, content }) {

  return (
    <Tabs>
    <TabList>
    {
            tabs !== undefined ?

            tabs.map(tab => {
                return(
                    <Tab>{tab.title}</Tab>
                )
            })
    : <></>

        }
    </TabList>
  {
            content !== undefined ?
            content.map(ct => {
                return(
                    <TabPanel>
                    <div className="em-c-tabs__body ">
                        <div
                        className="em-c-tabs__panel em-js-tabs-panel em-is-active "
                        id="tab-panel-1"
                        role="tabpanel"
                        aria-labelledby="tab-1"
                        >
                        {ct.data}
                        </div>
                    </div>
                    </TabPanel>
                )
            })
    : ''
    }
        </Tabs>
    );
}

export default TabElment;